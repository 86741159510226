import React from "react"
import styled from "styled-components"
import parse, { domToReact } from "html-react-parser"
import DesignTokens from "./designTokens"
import {
  Body,
  Caption,
  Heading,
  HeadingSmall,
  Display,
  DisplaySmall,
  Blockquote,
} from "./typography"

const RichContentTextBlock = ({ html, color }) => {
  const options = {
    replace: ({ name, children }) => {
      switch (name) {
        case "p":
          return <Body>{domToReact(children)}</Body>
        case "h1":
          return <Display as="h1">{domToReact(children)}</Display>
        case "h2":
          return <DisplaySmall as="h2">{domToReact(children)}</DisplaySmall>
        case "h3":
          return <Heading as="h3">{domToReact(children)}</Heading>
        case "h4":
          return <HeadingSmall as="h4">{domToReact(children)}</HeadingSmall>
        case "h5":
          return <Caption as="h5">{domToReact(children)}</Caption>
        case "h6":
          return <Caption as="h6">{domToReact(children)}</Caption>
        case "blockquote":
          return <Blockquote>{domToReact(children)}</Blockquote>
      }
    },
  }
  return <Wrapper color={color}>{parse(html, options)}</Wrapper>
}

const Wrapper = styled.div`
  color: ${props => props.color || "inherit"};
  width: 100%;
  & > * {
    margin-bottom: ${DesignTokens.spacing[3]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default RichContentTextBlock
